import React from "react"
import { graphql } from "gatsby";
import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';
import SEO from "../components/seo"

import { InterExtra, InterMedium, IbmMedium} from "../components/typography"
import { BtnPrimary } from "../components/button"
import {SubscribeWide} from "../components/subscribe"
import Fade from 'react-reveal/Fade';



function slugify(text)
  {
    return text.toString().toLowerCase()
    .replace(/\s+/g, '-').replace(/[^\w-]+/g, '').replace(/--+/g, '-').replace(/^-+/, '').replace(/-+$/, '');              
    // Replace spaces with -
    // Remove all non-word chars
    // Replace multiple - with single -
    // Trim - from start of text
    // Trim - from end of text
  }

export const query = graphql`
query GetRandomArticleNewExt{
    allDatoCmsArticle{
        edges {
            node {
            id
            title
            author {
                name
            }
            source {
                name
            }
            quote
            url
            category {
                title
            }
            backgroundcolor {
                hex
            }
            textcolor {
                rgb
            }
            }
        }
        totalCount
    }
}
`

class RandomExt extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        quoteText: "",
        backgroundColor:"",
        categoryTitle: "",
        textColor: "rgb(255,255,255)",
        author:"",
        title:"",
        titleUrl:"",
        url:"",
        tweet:""
      };
    }

    refreshPage(){
        window.location.reload();
    } 

    componentDidMount() {
        this.fetchRicksPupper()
    }

    componentWillUnmount() {
        this.refreshPage()
        document.clear();
    }
  
  
    render() {

      return (
        <div className="qn-bg-dark1">
        <SEO title="Random Highlight" />
        <Fade>
        <div className="centerer-var-fullheight flex items-center centerer-var-nomaxwidth" style={{backgroundColor: this.state.backgroundColor, color: this.state.textColor }}>
            <div className="ph32-l ph32-m ph16 mh32-l mh0-m mh0 w-100"> 
            <InterExtra className="pl4-l fw7 mb32-l mb32-m mb24">
              {this.state.quoteText}
              </InterExtra>
              <div className="w-100 flex-l items-center-l justify-between-l pl4-l ">
                <div className="w-60-l w-100-m w-100 db-l fl mb0-l mb24">
                  <IbmMedium className="fw5 pl4">{this.state.categoryTitle}</IbmMedium>
                  <IbmMedium className="fw3 pl4 truncate w-100-l db-l dn">
                    {this.state.author} <br/> {this.state.title}
                  </IbmMedium>
                  <IbmMedium className="fw3 pl4 truncate w-100-l dn-l db">
                    {this.state.author}
                  </IbmMedium>
                  <IbmMedium className="fw3 pl4 truncate w-100-l dn-l db">
                    {this.state.title}
                  </IbmMedium>
                </div>
                <div className="w-100-m db-l cn-l cl fn-l fl">
                  <BtnPrimary className="fl small mr16 dim" href={"../"+slugify(this.state.titleUrl)} target="_blank" customBgColor={this.state.textColor} customFillColor={this.state.backgroundColor}>
                    <InterMedium className="fw6 lh-solid">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                    </InterMedium>
                  </BtnPrimary>
                  <BtnPrimary className="fl small mr16 dim" href={this.state.url+"/?ref=insightshunt"} target="_blank" rel="noopener noreferrer" customBgColor={this.state.textColor} customFillColor={this.state.backgroundColor}>
                    <InterMedium className="fw6 lh-solid">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1z"/></svg>
                    </InterMedium>
                  </BtnPrimary>
                  <BtnPrimary className="fl small mr16 dim" target="_blank" rel="noopener noreferrer me" href={this.state.tweet} data-size="large" customBgColor={this.state.textColor} customFillColor={this.state.backgroundColor} >
                    <InterMedium className="fw6 lh-solid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path d="M8.2896 20.251C15.8368 20.251 19.9648 13.9982 19.9648 8.57584C19.9648 8.39824 19.9648 8.22144 19.9528 8.04544C20.7559 7.46457 21.4491 6.74534 22 5.92144C21.2511 6.25328 20.4567 6.4709 19.6432 6.56704C20.4998 6.05423 21.1409 5.24766 21.4472 4.29744C20.6417 4.7754 19.7605 5.11225 18.8416 5.29344C18.2229 4.63559 17.4047 4.19997 16.5135 4.05401C15.6223 3.90805 14.7078 4.05987 13.9116 4.48598C13.1154 4.9121 12.4819 5.58875 12.109 6.41123C11.7361 7.23371 11.6446 8.15616 11.8488 9.03584C10.2174 8.95405 8.62144 8.5301 7.16451 7.79151C5.70759 7.05292 4.42227 6.01619 3.392 4.74864C2.86727 5.65197 2.70656 6.72133 2.94258 7.73899C3.17861 8.75665 3.79362 9.6461 4.6624 10.2262C4.00939 10.2069 3.37062 10.0307 2.8 9.71264C2.8 9.72944 2.8 9.74704 2.8 9.76464C2.80026 10.712 3.12821 11.6301 3.72823 12.3633C4.32824 13.0964 5.16338 13.5994 6.092 13.787C5.4879 13.9518 4.85406 13.9759 4.2392 13.8574C4.50141 14.6728 5.01189 15.3858 5.69926 15.8967C6.38662 16.4076 7.21649 16.691 8.0728 16.707C6.61979 17.849 4.82485 18.4689 2.9768 18.467C2.65032 18.4664 2.32416 18.4466 2 18.4078C3.87651 19.6121 6.05993 20.2508 8.2896 20.2478"/>
                    </svg>
                    </InterMedium>
                  </BtnPrimary>
                </div>
              </div>

            </div>
        </div>
        </Fade>
        <SubscribeWide/>
            <div className="mh4 qn-nav">
                <div className="qn-nav-wrapper flex w-10-l w-20-m qn-bg-dark2 pt8-l pt16 pb8-l pb16 ph24 br-pill justify-center">
                <a href="/random-chrome" className="flex mt6-l mt0 qn-cl-grey1 qn-fi-grey1 qn-transition-cl">
                    <div className="db-l dn mr8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M17.65 6.35c-1.63-1.63-3.94-2.57-6.48-2.31-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20c3.19 0 5.93-1.87 7.21-4.56.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53-1.13 2.43-3.84 3.97-6.8 3.31-2.22-.49-4.01-2.3-4.48-4.52C5.31 9.44 8.26 6 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65z"/></svg>
                    </div>
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight db fw7">Refresh</p>
                </a>
                </div>
            </div>
        </div>
      );
    }

    // This data is fetched at run time on the client.
    fetchRicksPupper = () => {
        const { allDatoCmsArticle: { edges } } = this.props.data
        const randomPosition = Math.floor(Math.random() * ( (edges.length - 1) - 0 + 1)) + 0
        const randomizedQuote = edges[randomPosition].node
        const tweetText = "https://twitter.com/intent/tweet?text="+ randomizedQuote.quote + "– from &url=" + randomizedQuote.url + "&via=insights_hunt"
        const Unsplitted = randomizedQuote.quote
        const Splitted = Unsplitted.split("; ")[0]
        const randomBgColor = randomizedQuote.backgroundcolor.hex
        const randomTextColor = randomizedQuote.textcolor.rgb
        const randomCategoryTitle = randomizedQuote.category.title
        const randomAuthor = randomizedQuote.author.name
        const randomTitle = randomizedQuote.title
        const randomUrl = randomizedQuote.url
        this.setState({
            quoteText: Splitted,
            backgroundColor:randomBgColor,
            categoryTitle: randomCategoryTitle,
            textColor: randomTextColor,
            author: 'by ' + randomAuthor,
            title: 'in ' + randomTitle,
            titleUrl: randomTitle,
            url: randomUrl,
            tweet: tweetText,
          })
    }

  }
  
 export default RandomExt